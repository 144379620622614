import React from 'react'
import { Pagination } from 'react-bootstrap'
import { range } from '../utils'

const Paginator = props => {
  const { slug, currentPage, totalPages } = props
  return (
    <Pagination className="justify-content-center">
      <Pagination.Prev
        href={
          currentPage === 2 ? `/${slug}/` : `/${slug}/page/${currentPage - 1}/`
        }
        children="Prev"
        disabled={currentPage === 1}
      />
      {totalPages <= 10 ? (
        Array.from({ length: totalPages }).map((_, i) => (
          <Pagination.Item
            key={i}
            href={i === 0 ? `/${slug}/` : `/${slug}/page/${i + 1}/`}
            active={currentPage === i + 1}
          >
            {i + 1}
          </Pagination.Item>
        ))
      ) : (
        <>
          <Pagination.Item href={`/${slug}/`} active={currentPage === 1}>
            {1}
          </Pagination.Item>
          {currentPage > 4 && <Pagination.Ellipsis />}
          {range(
            currentPage + 3 <= totalPages
              ? currentPage < 4
                ? 2
                : currentPage - 2
              : totalPages - 4,
            currentPage + 3 <= totalPages
              ? currentPage < 3
                ? 5
                : currentPage + 2
              : totalPages - 1
          ).map((n, i) => (
            <Pagination.Item
              key={i}
              href={`/${slug}/page/${n}/`}
              active={currentPage === n}
            >
              {n}
            </Pagination.Item>
          ))}
          {currentPage + 3 < totalPages - 1 && <Pagination.Ellipsis />}
          <Pagination.Item
            href={`/${slug}/page/${totalPages}/`}
            active={currentPage === totalPages}
          >
            {totalPages}
          </Pagination.Item>
        </>
      )}
      <Pagination.Next
        href={`/${slug}/page/${currentPage + 1}/`}
        children="Next"
        disabled={currentPage === totalPages}
      />
    </Pagination>
  )
}

export default Paginator
