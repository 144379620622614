import BackgroundImage from 'gatsby-background-image'
import React from 'react'

const Hero = props => {
  const { type, image, title } = props
  const className = `hero ${type}`

  return (
    <BackgroundImage fluid={image}>
      <section className={className}>
        {/* <Image src={image} className={`${className}__image`} fluid={false} /> */}
        <div className={`${className}__overlay`} />
        { title && <h1 className={`${type}__title`} dangerouslySetInnerHTML={{ __html: title }} />}
      </section>
    </BackgroundImage>
  )
}

export default Hero
