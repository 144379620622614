export const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export const encodeToFormData = data => {
  const formData = new FormData()
  for (const [key, value] of Object.entries(data)) {
    if (Array.isArray(value)) {
      for (const el of value) formData.append(`${key}[]`, JSON.stringify(el))
    } else if (typeof value === 'object') {
      for (let subKey in value)
        formData.append(`${key}.${subKey}`, value[subKey])
    } else formData.append(key, value)
  }
  return formData
}

export const getFormattedTime = () => {
  const today = new Date()
  const y = today.getFullYear()
  const m = today.getMonth() + 1 // JavaScript months are 0-based
  const d = today.getDate()
  const h = today.getHours()
  const mi = today.getMinutes()
  const s = today.getSeconds()
  return `${y}-${m}-${d}_${h}-${mi}-${s}`
}

export const groupByN = (n, data) => {
  const result = []
  for (let i = 0; i < data.length; i += n) result.push(data.slice(i, i + n))
  return result
}

// range(start, stop, step) util
export const range = (start, stop, step = 1) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step)
