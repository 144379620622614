import React from 'react'

import Img from 'gatsby-image'
import { Link } from 'gatsby'

const Post = ({ title, featuredImage, link, excerpt, date, author }) => {
  return (
    <div className="post">
      <Img fluid={featuredImage} className="post__image" />
      <div className="post__wrapper">
        <div className="post__sub-heading">Freight Shipping</div>
        <Link to={link}>
          <div className="post__heading">{title}</div>
        </Link>
        <div
          className="post__text"
          dangerouslySetInnerHTML={{ __html: excerpt }}
        />
        <div className="post__sub-wrapper">
          <div className="post__author">By {author}</div>
          <div className="post__date">Posted {date}</div>
        </div>
      </div>
    </div>
  )
}

export default Post
